<template>
  <div class="landing">
    <div class="landing__bottom-gradient"></div>
    <div class="landing__logo logo">
      <img src="@/images/logo.webp" alt="logo" class="logo__img">
    </div>
    <div class="landing__bonus-box"></div>
    <div class="landing__streamer">
      <img src="@/images/streamer.webp" alt="streamer roshtein" class="landing__streamer-img">
    </div>
    <div class="landing__playing-cards">
      <div class="landing__playing-cards-img"></div>
    </div>
    <div class="landing__enjoy" v-html="textStrings.enjoyThePlay"></div>
    <div class="landing__welcome-bonus welcome-bonus">
      <div class="welcome-bonus__title">{{ textStrings.welcomeBonus }}</div>
      <div class="welcome-bonus__text" v-html="textStrings.welcomeBonusText"></div>
    </div>
    <div class="landing__actions">
      <div class="landing__steps steps">
        <a :href="textStrings.referralLink" class="steps__step" v-for="(step, number) in textStrings.steps" :key="number">
          <div class="steps__step-number">{{ number + 1 }}</div>
          <div class="steps__step-text">{{ step }}</div>
          <div class="steps__step-arrow"></div>
        </a>
      </div>
      <a :href="textStrings.referralLink" class="landing__land-button land-button">
        <span>{{ textStrings.landButton }}</span>
      </a>
    </div>
  </div>
</template>

<script>
const TEXT_STRINGS = {
  welcomeBonus: 'Welcome bonus',
  welcomeBonusText: '<span>100%</span><div>+ UP TO</div><span>500 FS</span>',
  steps: ['REGISTER', 'DEPOSIT', 'PLAY'],
  referralLink: 'https://stardacasino.life/c0e6b0356',
  landButton: 'get the bonus',
  enjoyThePlay: 'Enjoy <br>the <br>play'
}
export default {
  name: 'App',
  data () {
    return {
      textStrings: TEXT_STRINGS
    }
  },
  mounted () {
    this.appHeight()

    window.addEventListener('resize', this.appHeight)
  },
  methods: {
    appHeight () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }
}
</script>

<style lang="scss"></style>
